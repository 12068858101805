import React, { useEffect } from "react";
import Layout from "../../components/commons/Layout";
import styled from "styled-components";
import theme from "../../constants/theme";

import DynamicZone from "../../components/commons/DynamicZone";
import PageTransition from "../../components/animations/PageTransition";
import CasiStudioSection from "../../components/homepage/CasiStudioSection";
import SectionHeroFullScreen from "../../components/sections/SectionHeroFullScreen";
import ResponsiveText from "../../components/typography/ResponsiveText";
import Header from "../../components/commons/Header";
import Seo from "../../components/commons/Seo";
import Cursor from "../../components/commons/Cursor";

const CasiStudioDetailWrapper = styled.div`
  display: block;
  flex-direction: column;

  .detail-header {
    display: flex;
    flex-flow: column wrap;
    min-height: 100vh;

    h2 {
      font-size: 30px;
      text-transform: uppercase;
      line-height: 36px;
      margin-bottom: 30px;
    }

    h1 {
      margin-bottom: 20px;
    }

    .detail-image {
      flex: 1;
    }
  }

  ul.tags {
    margin-bottom: 30px;
    width: 100%;
    @media (min-width: 1280px) {
      white-space: normal !important;
    }

    li {
      display: inline-block;
      font-size: 21px;
      background-color: ${theme.colorYellow};
      padding: 5px;
      @media (min-width: 1280px) {
        margin-bottom: 5px;
      }
      text-transform: uppercase;
      margin-right: 25px;
    }
  }
`;

export default function ServiziDetail({
  location,
  pageContext: { node, casi, seoRules },
}) {
  const { titolo, sezioniDinamiche, immagineDettaglio } = node;

  const seo = seoRules && seoRules[0] && seoRules[0].seo

  

    	/**
   * GESTIONE Seo
   */
  
	const seoProps = {
		title: '',
		description: '',
		keywords: '',
		// image: featuredImage && featuredImage.src
	}
  
	if (seo) {
		seoProps.title = seo.title
		seoProps.description = seo.description
		seoProps.keywords = seo.keywords
	}


  return (
    <>
      <Cursor />
      <Header />
      <Seo seo={seoProps} />
      <CasiStudioDetailWrapper>
        <SectionHeroFullScreen
          title={titolo}
          featuredImage={immagineDettaglio}
          titleResponsiveType="servizi"
          isDark
        />
        <DynamicZone sezioniDinamiche={sezioniDinamiche} />
      </CasiStudioDetailWrapper>
    </>
  );
}
